import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Design for Excellence",
  "description": "The different design (DFA, DFM, etc.) approaches to develop a excellent functioning product.",
  "author": "Declan Liu",
  "categories": ["turnkey"],
  "date": "2022-08-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", "", "", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "design-types-and-services"
    }}>{`Design Types and Services`}</h2>
    <p>{`During the process of developing a product, you'll be faced with various design approaches, each with a specialized requirements. `}<strong parentName="p">{`DFX`}</strong>{`, or also known as `}<strong parentName="p">{`Design for X`}</strong>{` or `}<strong parentName="p">{`Design for Excellence`}</strong>{`, is a management theory which correlates different teams to contribute in the design process. By having this collaborative work across teams, the process of product design and manufacturing shortens the product development cycle, improve the product's quality, increase the company's reliability and customer's satisfaction, and ultimately shorten the entire product lifecycle from just the design concept into a ready made product. `}</p>
    <p>{`The different types of design included in the DFX family includes:                   `}</p>
    <ul>
      <li parentName="ul">{`Design for Supply Chain: DFSC                `}</li>
      <li parentName="ul">{`Design for Manufacture: DFM                  `}</li>
      <li parentName="ul">{`Design for Testability: DFT                `}</li>
      <li parentName="ul">{`Design for Assembly: DFA                          `}</li>
      <li parentName="ul">{`Design for Environmental: DFE                   `}</li>
      <li parentName="ul">{`Design for Serviceability: DFS                `}</li>
      <li parentName="ul">{`Design for Cost: DFC `}</li>
    </ul>
    <h3 {...{
      "id": "1-dfsc-design-for-supply-chain"
    }}>{`1. DFSC (Design for Supply Chain)`}</h3>
    <p><strong parentName="p">{`Maturity`}</strong>{`: Low `}</p>
    <p><strong parentName="p">{`Personnel involved`}</strong>{`: Product Designers, Procurement Engineers `}</p>
    <p>{`At the moment, DFSC has only been proven as a mere concept, with no definite methodology yet. However, the purpose of developing a DFSC strategy is crucial to establish the communication between the product designer and the supply chain personnel to confirm whether the designed product's parts should be made or bought. `}</p>
    <h3 {...{
      "id": "2-dfm-design-for-manufacture"
    }}>{`2. DFM (Design for Manufacture)`}</h3>
    <p><strong parentName="p">{`Maturity`}</strong>{`: High `}</p>
    <p><strong parentName="p">{`Personnel involved`}</strong>{`: Suppliers, Process Engineers, Designers `}</p>
    <p>{`DFM is by far the most mature methodology throughout the whole DFX strategy. DFM involves the process of checking your design to verify its manufacturability early in the development process. This thus saves time and development costs, increases productivity, and reduces time to market. `}</p>
    <p>{`The process of DFM is generally conducted with multiple rounds of communication, which lasts for about a week each. The whole DFM process typically lasts for two months. If you are a product designer, we suggest that you don't conduct the process alone. Collaborating with your contract manufacturers will be a much greater option since different companies have different capabilities and preferences. `}</p>
    <p>{`In NexPCB, our experts conducts DFM efficiently through the cooperation between different suppliers and usage of different materials.   `}</p>
    <p>{`This part is readily accessible from numerous software, including Solidworks:`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.solidworks.com.cn/sw/products/3d-cad/design-for-manufacturability.htm"
      }}>{`https://www.solidworks.com.cn/sw/products/3d-cad/design-for-manufacturability.htm`}</a>{` `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "554px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.79536679536679%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACVElEQVQoz22R2WoUQRSG+w18Bd9D8Ea8EcQLwQfwRkXEXCqCNwqKCEaNYsCYmEgmcYsKCWTAxATBZSYmksSZZCaZtXt6equqrmW6u7rqyGR3+a4OxflP/ef8BvyDUqrT4RtV29oMlJQI405HpKk82MOiCAlu/CFLZRgS13NBqXeTd072nW2gDUihWXdYlDqOkyQJACDOWj+WouXVHXESx5jSkKdKaqUBdPyg96Lx8NiFgWuFTev1zI2ZV7fL6xXGKGglAcTSZPzpiaG1BgAP4c2fC1+zVxcLH2WiIBETs7Xzw1N9s/P3MjcvnzAenz7KCCU09D2n3Rau8xJgqPuzTlMAwBOTl3qMQ48OF5ZW8mbt3Mjd7Lec76HmRv7Li36rZheKxcD3U5WAhJLMLqiBHdshY8219fHFzJW54czn70fuHz913Zh7f0sqoIzzRIY0DMNw26YM5FhrbFAN7dr2vLbrdrfREFDWmzkz/qxnfXXZsqy2bfuuu39U0JDCdFJ5mq7sX1trRTkNMHLaTqVcd33SNM1KpRJF0V9Zxp432mgNJmDsKvVeIaWs1Wtms0Ew5pwTQjDGjDEpu1F3swA1lef90x1jT3aQ7UettRAiDEOMMdmCUpqmihD/16pdrypDJB2P+VZoBwLzWMD/kFJSSrdHoAC12lajWWcUG9m1+ef5NyMLb0cXP5S9arc1lUopSqnneYSQOI6VUlprpRQhJIkTkYj1asVsWkbZrNdt03Tsaqth+y5nPEABQqhUKuVyuWKxaJqm7/sIIbYFpVRwEXUiSulv+kC8juz7UukAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5a16a284c64b4a5ad6c36c521e0abe29/652f5/DFX-02.webp 259w", "/static/5a16a284c64b4a5ad6c36c521e0abe29/c29d2/DFX-02.webp 518w", "/static/5a16a284c64b4a5ad6c36c521e0abe29/5e3e0/DFX-02.webp 554w"],
            "sizes": "(max-width: 554px) 100vw, 554px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5a16a284c64b4a5ad6c36c521e0abe29/a2ead/DFX-02.png 259w", "/static/5a16a284c64b4a5ad6c36c521e0abe29/6b9fd/DFX-02.png 518w", "/static/5a16a284c64b4a5ad6c36c521e0abe29/04abd/DFX-02.png 554w"],
            "sizes": "(max-width: 554px) 100vw, 554px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/5a16a284c64b4a5ad6c36c521e0abe29/04abd/DFX-02.png",
            "alt": "DFM",
            "title": "DFM",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <p>{`The image shown is a display of mold flow analysis, which can simulate the flow of plastic in the mold, and analyze the risk positions of material shortage, shrinkage, and deformation that affect structural performance.`}</p>
    <h3 {...{
      "id": "3-dft-design-for-testability"
    }}>{`3. DFT (Design for Testability)`}</h3>
    <p><strong parentName="p">{`Maturity`}</strong>{`: High `}</p>
    <p><strong parentName="p">{`Personnel involved`}</strong>{`: Testing Engineer, Process Engineer, Product Designer `}</p>
    <p>{`The process of testing is one of the most crucial parts in electronic product development. After producing the PCBA, the test engineer needs to put the PCBA on the test tool and burn in the program to test each function. `}</p>
    <p><a parentName="p" {...{
        "href": "images/DFX-03.png"
      }}>{`DFT Design for Testability`}</a></p>
    <h3 {...{
      "id": "4-dfa-design-for-assembly"
    }}>{`4. DFA (Design for Assembly)`}</h3>
    <p><strong parentName="p">{`Maturity`}</strong>{`: High `}</p>
    <p><strong parentName="p">{`Personnel involved`}</strong>{`: Process Engineer, Production Department, Product Designer `}</p>
    <p><a parentName="p" {...{
        "href": "/articles/DFA"
      }}>{`DFA`}</a>{` focuses on easing the assembly process to be more efficient. This is done by minimizing the number of parts, tools, and processing to reduce the cost of labor hours during assembly. `}</p>
    <h3 {...{
      "id": "5-dfe-design-for-the-environment"
    }}>{`5. DFE (Design for the Environment)`}</h3>
    <p><strong parentName="p">{`Maturity`}</strong>{`: Low `}</p>
    <p><strong parentName="p">{`Personnel involved`}</strong>{`: Material Suppliers, Process Engineers, Product Designers `}</p>
    <p>{`If the material chosen is resistant to weathering, they should be degradable as much as possible. If the material is not resistant to weathering, consider using the same material to facilitate the process of disassembly and regeneration when the product is scrapped and recycled.`}<br parentName="p"></br>{`
`}{`Currently, some of the most common degradable materials are PLA (polylactic acid), PHA (polyhydroxyalkanoate), and PGA (polyglutamic acid). `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.826254826254825%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAAnAAAAJwEqCZFPAAACgklEQVQozwXBe1PSAAAA8H2ADAVPAd0A0YYy2WBsikNAYLw2BvIMxt48nAorSM2oy+p62NlJpZ7+5XVmn7PfD5halhcR3oo0res86NfmMN2Jy1ak5cJlJ6nbiYEDV0BUWiFViNAh4sDpbznQ1gouQlgHsGGSdXbOYbEsz5tdbq9vR/OBdpWO0j543R8jaC3ksu+zqbAbQoK5UJzPrLqMPB1yQfhmEZgnxAXTDM9x7HbY6/J4omKZDN79/MX4kA085k9Kciz06fQ05XYQIXYr2RzlYieNCk+iUboBWCkZtZhPTt5ogpTxYwTTHqRj13cPwtZmLceFGHWQ3j7/9qO7HSwU+RTTvDS0y8nlR6VerWrAfEJ1P50aqmqTYeoxCskpYnDt/uL7MLUtVupwstnCnt1+PhskN9hiIxBhv/TV+8fHQZ5O5WqANa1AJtPbrtTKZeIYupIV9OTGv9sbMRQoZxk3XdcTxM3XD2rIl8qVAnRxYii/r6/kcDCZLQFWRoJnZ8a60n1ei3o93oJ4XNh5+PNXIlGhXELy/IiNXE0uOxSe5ip0tXV3yL9qlAyaYistwMYJy6apFwpf5DivA/JVpF6SmpxfUAu2RDrrL4sVDB6PhhQEEikmUm2OMlvDYno3GKC4KgCWeBCEohtB3I/BKOoX2oFVTzFGYTBMMRwpddZcrgSJry8t4WxhsyHEcSyf2EE9XrJYAUy7dXuNjw+MSN9YFDULryK9A/Z0HD9+vdQ9nJV7pDHafX+WOR2D7X2L1A0Pj7jxu8jwaF7QgKlS6Um1ttTuODtdEy+aBHlObcN9Y7Gnm9XutNKx9XSP8XJx78Cs9aaV9sKeDvcHtnZ3WpT/AwMAv7cZKdEoAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/69543c5ea697084c6455499b4c7d5913/652f5/DFX-05.webp 259w", "/static/69543c5ea697084c6455499b4c7d5913/c29d2/DFX-05.webp 518w", "/static/69543c5ea697084c6455499b4c7d5913/77d2c/DFX-05.webp 1035w", "/static/69543c5ea697084c6455499b4c7d5913/92f8c/DFX-05.webp 1200w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/69543c5ea697084c6455499b4c7d5913/a2ead/DFX-05.png 259w", "/static/69543c5ea697084c6455499b4c7d5913/6b9fd/DFX-05.png 518w", "/static/69543c5ea697084c6455499b4c7d5913/e3189/DFX-05.png 1035w", "/static/69543c5ea697084c6455499b4c7d5913/c1b63/DFX-05.png 1200w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/69543c5ea697084c6455499b4c7d5913/e3189/DFX-05.png",
            "alt": "DFE Design for the Environment",
            "title": "DFE Design for the Environment",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h3 {...{
      "id": "6-dfs-design-for-serviceability"
    }}>{`6. DFS (Design for Serviceability)`}</h3>
    <p><strong parentName="p">{`Maturity`}</strong>{`: Medium `}</p>
    <p><strong parentName="p">{`Personnel involved`}</strong>{`: market, designer
Within a large batch of marketed product, you'll find a lot of the products require repairs or returned since it's damaged. The damage of a product during its utilization is inevitable, but are constantly reduced. Among the parts which made up the damaged product, there's usually one or a few parts that have a lower lifespan than the other parts.
When dealing with this damage, the more vulnerable part should be separated into modular forms so it would be more convinient for later maintenance. By doing this, if a product is damaged due to this part, repair can be conducted more efficiently since only one part will be repaired. Thus reducing the risk of repair damage to the whole product.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.90347490347492%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACVElEQVQoz3VTS2sTURTOfxB/gAjqzhZ34l4XBbf+hAqCuCxGtKAL+6C0G0GhDbEFibTWaJvm1byneczcpOnkMZlHZjJ5NK9Jpk1Gkk7mSJISIonf6p5z73e+e8/9jk7TNJiA9i96A2gT0I3I44sRAGBIG8/3er1hqBuPC4Vio9kcFbq6UmvVGs3QHM/n8/lxgSlkQRRNpl2ECKle10DrdruCIMZCQY/5FwpH5GYDADqdDsuw19ce1ZMacprmQhEimUiJoliulBuNerfTEUN+we+yGg2Wn2aWpo+ObHfvzer17wAGZABQFKVQrOQLlTARz+WLdamW5TlOyHJxVI1glUy8jAfCLqfH7Z6ff3nj5q3FxQ8AoFNVVZblfLGc5QvFUiVFZWKncZ5nM5kUQ1MC5qviwdKJVzjxqYridh7fvjNzf/bhzrYREcG+8t6P/e+7ZoblSTJJIOLg4DCdSpKJMwYPCx5nLuCm7ZYyxwCAzeqYefDo+YtXXw1fwiFM12q1554+2zLsnJEphKKSJEWjISzgZlk6sL9H/DazAW864JOqFQAQBcbpsFkshysf36uqqpMvLpfWPi2tff5m2q/VqsMWRBFOkom2fJHG8aDdlqUo/Zu3OI4QIhTlMhaNJBNk/80AwPNZq92Fo9OBJfqdJ8nk8uqG2+s/drloinI6HOvrGyvLq0bjNgCoqjrU6JPb7Xar1Rr9maZpPJ9TlD+l83OTyfR6YeHxkzkMw7Y2N5sDC408N8We4yYFAIpK22z2yQPX5MnsNMNPjs+Y8v8wHKmpW38B3qkcQAXMoZcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/eed11de97edd982664c566b05e03f58f/652f5/DFX-06.webp 259w", "/static/eed11de97edd982664c566b05e03f58f/c29d2/DFX-06.webp 518w", "/static/eed11de97edd982664c566b05e03f58f/e88ff/DFX-06.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/eed11de97edd982664c566b05e03f58f/a2ead/DFX-06.png 259w", "/static/eed11de97edd982664c566b05e03f58f/6b9fd/DFX-06.png 518w", "/static/eed11de97edd982664c566b05e03f58f/0a47e/DFX-06.png 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/eed11de97edd982664c566b05e03f58f/0a47e/DFX-06.png",
            "alt": "DFS",
            "title": "DFS",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <p>{`As shown in the figure, the display screen and the battery are fragile, hence these two pieces are designed into two modules.`}</p>
    <h3 {...{
      "id": "7-dfc-design-for-cost"
    }}>{`7. DFC (Design for Cost)`}</h3>
    <p><strong parentName="p">{`Maturity`}</strong>{`: High `}</p>
    <p><strong parentName="p">{`Personnel involved`}</strong>{`: Procurement Engineers, Product Designer `}</p>
    <p>{`Cost is the inescapable consideration all behaviors. Whether it is short-term or long-term cost, it is necessary to maximize the utilization of funds. During the process of designing a product, each of the parts' weight can be calculated using the software simulation. With this algorithm, the mechanical properties of a part can be adjusted. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.37065637065637%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACPUlEQVQozzWRQU8TQRiG92pUTvgX+AFgTPREuAA14cLRGpAjdwP7A5AAUUyahYMnUxM9mGhiiiQUvKgpbtKDELCFttvZZXZLp912d2dmZ3c7n0kXnvP3fHnfvIplWY7jEEL6/T6llDHGOZdSAkAYhpxzOqRUKk1MTIyNjeVyOUIIQsgwDAVj3G63Pc9jjAkh4jgGgGLx4MfRYRiGURQFQYAxfpZ9Pjr64P7IiKqqvu9bloUQUlqtluu6jLEoilJT1/WnmZknjx+p6gqlNIqik5O/4+MP79y9NzObaTabrus6jmNZltJut33fF0IkSSKlpJQWCntra+uLiwv5/HsA4JxrmjY9Pf1iaalUKnHOXdftdDoYY6XT6aTvkyQBACGEtrO7ubFVLBaDIACAQqEwNTU1Nzd3enoKAP6Qbrdr27ZCCEllIQQAlMvlxYWsrv+hlAJAtVrJZrOTk5PLy8u2bQshGGNBENzIrVbL87wwDKWU/X5/deXl1uYGAEgpG4aR03ZnM5n5+fnj4+O0Aufc9/2b2JZlpTsRQva/7337+gUhJISQcqDr+lvt3cb2jqbtCCHCIZTSXq93fX1tmqbSaDRM0zw/P3/9ZntVVcvlchzH0RAA+Pnr98dPnw8Oj1KTMeZ5HiEEY9xsNpWLi4tarVav13Vdz+c/rL1ar1T+JUmSXsdxPBgM4jjinAdBkAa0bRshVK/XlbOzs0qlUqvVrq6ubIwRahJCere4rtu9hRDiOI5pmoZhXF5eVqvV/2TvIAjWGC17AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/56430096ca36624ac834aa940e5f67ed/652f5/DFX-07.webp 259w", "/static/56430096ca36624ac834aa940e5f67ed/c29d2/DFX-07.webp 518w", "/static/56430096ca36624ac834aa940e5f67ed/77d2c/DFX-07.webp 1035w", "/static/56430096ca36624ac834aa940e5f67ed/af3f0/DFX-07.webp 1280w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/56430096ca36624ac834aa940e5f67ed/a2ead/DFX-07.png 259w", "/static/56430096ca36624ac834aa940e5f67ed/6b9fd/DFX-07.png 518w", "/static/56430096ca36624ac834aa940e5f67ed/e3189/DFX-07.png 1035w", "/static/56430096ca36624ac834aa940e5f67ed/21b4d/DFX-07.png 1280w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/56430096ca36624ac834aa940e5f67ed/e3189/DFX-07.png",
            "alt": "Design for Cost Efficiency",
            "title": "Design for Cost Efficiency",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` `}</p>
    <p>{`In this case, the left part can modified to reduce the material cost and transportation cost (can be packed in a more compact manner) by re-designing it to be like the part on the right. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "802px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.27027027027026%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB0UlEQVQ4y42UWYvCQBCE5///F0FfREFRSbwxwSOaeN+34n1rLdUQ8WHNbqBJMpnp/qp6Jur1euFbfLu8vil4XIfDAdPpFMvlEvP5HJPJBJvN5p30t1CcPB6PMZvNZDHvfN/v91iv1+j3+ygWi9A0DaZpYjgcejFANZtN5HI56JqGZDKJTCaDbDYLx3FQKpUkfD4fAoEAWq2WFOJ1vV5xv99xu90kLpcLns8n1Ha7FbLRaCSSGCS0bVuoDMNAoVCQQqRtt9uSuF6vyxwCdbtddDodsUUx8+l0wuPxwPl8flfs9XqSwLIs8ZBWUC6Jo9GojMdiMaRSKQnaQjCRTG+CwaCQMEmtVkOlUhEaVh0MBqhalpBxbLFYCMBnuBYoZmVS4nMB39kMymBVBmWzUKPRkLvr47+3DbcGi5CU0klIjym3XC6/O00iBgndZ0UaNoLS6BP94qJEIiHJ6BWTs+ucF4lEEAqFZJz01WoVpmGIRWyMokx6F4/HxWwmo6zj8SgknOj3+4WWpLvdTsbz+bx0nlsunU5LH2iTcrvHJG6sViuRRJn0LxwOC41j29IQngjK475zw23O16PHj5RMCtLoui4b/8+T4vVj+NwWHHOfvc7yD2lyFBqYjCuEAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/ce5d952b351f3d0e62cae8acadc7f531/652f5/DFX-08.webp 259w", "/static/ce5d952b351f3d0e62cae8acadc7f531/c29d2/DFX-08.webp 518w", "/static/ce5d952b351f3d0e62cae8acadc7f531/85811/DFX-08.webp 802w"],
            "sizes": "(max-width: 802px) 100vw, 802px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/ce5d952b351f3d0e62cae8acadc7f531/a2ead/DFX-08.png 259w", "/static/ce5d952b351f3d0e62cae8acadc7f531/6b9fd/DFX-08.png 518w", "/static/ce5d952b351f3d0e62cae8acadc7f531/5a6dd/DFX-08.png 802w"],
            "sizes": "(max-width: 802px) 100vw, 802px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/ce5d952b351f3d0e62cae8acadc7f531/5a6dd/DFX-08.png",
            "alt": "Design for Excellence",
            "title": "Design for Excellence",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`   `}</p>
    <p>{`When evaluating the DFX design, you can’t just look at one of the optimizations. You need to consider all aspects comprehensively, because they are mutually restrained, and sometimes improving one of them affects the others. Decrease and increase.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      